import axios from 'axios';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { session } from '../../classes/session';
import { AUTH_CLIENT_ID, AUTH_HOSTNAME } from '../../config';

export class AuthCallback extends Component {

  constructor(props: any) {

    super(props);

    const searchCopy = JSON.parse(JSON.stringify(window.location.search));
    this.login(searchCopy);

    

  }

  async login(search: string) {

    const tokens = await axios.get(`https://${AUTH_HOSTNAME}/auth/callback/${AUTH_CLIENT_ID}${search}&redirectUri=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/auth/callback`)}`)
    session.setToken(tokens.data.access_token);

  }

  render() {
  
    return <Navigate to="/" replace={true} />;

  }

}