import { Component, createRef, RefObject } from 'react';
import { PropsWithChildren } from 'react';
import { Map, Circle as CircleType, polyline, LatLngTuple, divIcon, marker, LatLngBoundsExpression } from 'leaflet';
import { MapContainer, Tooltip, TileLayer, Circle } from 'react-leaflet'
import { session } from '../../classes/session';
import randomColor from 'randomcolor';
import { socket } from '../../classes/socket';

// dirty include legacy leaflet plugin
// @ts-ignore
import * as slideTo from 'leaflet.marker.slideto';
// eslint-disable-next-line
(() => { return slideTo; });

type StateObject = {
  assets: Record<string, any>;
  draw: boolean;
}

const TWO_MONTHS = 1000 * 60 * 60 * 24 * 60;

export class ObservationMap extends Component {

  mapRef: RefObject<Map>;
  assetRef: Record<string, RefObject<CircleType>>;

  polyline: Record<string, any>;

  state: StateObject = {
    assets: {},
    draw: false,
  };

  constructor(props: PropsWithChildren) {

    super(props);

    this.mapRef = createRef();
    this.assetRef = {};
    this.polyline = {};

  }

  componentDidMount() {

    session.addListener('options', this.onOptions.bind(this));
    socket.addListener('entityEvent', this.onEntityEvent.bind(this));

  }

  componentWillUnmount() {

    session.removeListener('options', this.onOptions.bind(this));
    socket.removeListener('entityEvent', this.onEntityEvent.bind(this));


  }

  onEntityEvent({ entity, operation, document}: any) {

    if(entity === 'Observation') {

      this.onObservation(document);

    }

  }

  onObservation(observation: any) {

    if(!this.assetRef[observation.asset._id]) {

      return;

    }

    const latlng: LatLngTuple = [observation.asset.position.coordinates[1], observation.asset.position.coordinates[0]];

    // @ts-ignore
    this.assetRef[observation.asset._id].current?.slideTo(latlng)
    this.polyline[observation.asset._id]?.addLatLng(latlng);

    if(this.mapRef.current) {

      const eventIcon = divIcon({ className: `eventIcon asset-${observation.asset?._id}`, html: `<div>${observation.event}</div>` });
      marker(latlng, { icon: eventIcon }).addTo(this.mapRef.current);

    }


    if(session.getOption('followAsset') === observation.asset._id) {

      this.mapRef.current?.flyTo(latlng);

    }

  }

  async onOptions({ key, value }: any) {

    if(key === 'followAsset') {

      if(this.state.assets[value]) {

        const position = this.state.assets[value].position;
        if(position && position.coordinates) {

          this.mapRef.current?.flyTo([position.coordinates[1], position.coordinates[0]]);

        }

      }

    } else
    if(key === 'assets') {

      this.onAuth(value);

    }

  }

  async onAuth(assetsPreloaded?: any[]) {

    let assetResult = assetsPreloaded || session.getOption('assets');

    if(assetResult.length === 0) {

      // set state
      this.setState({
        assets: {},
      });

      return;

    }

    // map to object by id
    const assets: Record<string, any> = {};
    const coordinates: LatLngBoundsExpression = [];
    await assetResult.forEach((asset: any) => {

      const color = randomColor({
        seed: asset._id,
      })
      
      // save asset
      assets[asset._id] = {
        ...asset,
        color,
      };

      // save ref
      this.assetRef[asset._id] = createRef();

      if(this.mapRef.current && asset.position != null) {

        // line
        this.polyline[asset._id] = polyline([
          [asset.position.coordinates[1], asset.position.coordinates[0]]
        ], {
          color: color,
          className: `asset-${asset._id}`
        }).addTo(this.mapRef.current);

        if(new Date(asset.updatedAt).getTime() > new Date().getTime() - TWO_MONTHS) {

          coordinates.push([asset.position.coordinates[1], asset.position.coordinates[0]]);

        }

      }

      if(coordinates.length > 0) {
    
        this.mapRef.current?.fitBounds(coordinates, { 
          padding: [50, 50],  
        });

      }

      
    });

    // set state
    this.setState({
      assets,
    });

  }


  onResize() {

    this.mapRef?.current?.invalidateSize();

  }

  render() {

    return <MapContainer ref={this.mapRef} style={{ width: '100%', height: '100%' }} center={[52.284984852459566, 6.780614871777547]} zoom={20}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {Object.keys(this.state.assets).map((assetId) => {

        const asset = this.state.assets[assetId];

        if(!asset.position || !asset.position.coordinates) {

          return null;

        }

        // only show updated in last 60 days
        if(new Date(asset.updatedAt).getTime() < new Date().getTime() - TWO_MONTHS) {
            
          return null;

        }

        console.log('render', assetId, this.state.assets[assetId])

        // marker
        return <Circle
          key={asset._id}
          center={[asset.position.coordinates[1], asset.position.coordinates[0]]}
          ref={this.assetRef[asset._id]}
          radius={Math.max(1, asset.position?.properties?.accuracy || 0)}
          fillColor={asset.color}
          color={asset.color}
          className={`asset-${asset._id}`}
        >
          <Tooltip
            permanent={true}
            direction={'top'}
            offset={[0, -10]}
            className={`asset-${asset._id}`}
          >{asset.name}</Tooltip>
        </Circle>;
        
      })}
    </MapContainer>;

  }

}