import React, { PropsWithChildren, RefObject } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ObservationList } from './components/observations';
import { ObservationMap } from './components/map';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import  './style/default.scss';
import { Menu } from './components/menu';

class App extends React.Component {

  state = {
    height: 300,
  };

  mapRef: RefObject<ObservationMap>;

  constructor(props: PropsWithChildren) {

    super(props);

    this.state = {
      height: parseInt(localStorage.getItem('mapHeight') || '300', 10),
    }

    this.mapRef = React.createRef();

  }

  // On top layout
  onResize = (event: any, data: any) => {

    this.setState({
      height: data.size.height,
    });

    localStorage.setItem('mapHeight', data.size.height);

    this.mapRef?.current?.onResize();

  };



  render() { 

    return (
      <Container fluid={true} style={{ height: '100vh' }} className="d-flex flex-column align-items-stretch">
        <Row>
          <Col xs={12} style={{ backgroundColor: '#1a72ba' }}>
            <Menu />
          </Col>
        </Row>
        <Row className="justify-content-md-center" style={{ flexGrow: 1 }}>
          <Col xg={12} style={{ maxHeight: `calc(100vh - 64px - ${this.state.height}px)`}}>
            <ObservationList />
          </Col>
        </Row>
        <Row>
          <Resizable
            height={this.state.height}
            width={window.innerWidth}
            onResize={this.onResize}
            axis={'y'}
            resizeHandles={['n']}
            handle={(
              <div style={{ position: 'absolute', top: -1, width: '100%', overflow: 'visible', zIndex: 1000, cursor: 'ns-resize' }}>
                <div style={{ height: 2, backgroundColor: 'black' }}></div>
              </div>
            )}
          >
            <Col xg={12} style={{ height: this.state.height, backgroundColor: 'yellow', padding: 0 }}>
              <ObservationMap ref={this.mapRef} />
            </Col>
          </Resizable>
        </Row>
      </Container>
    );

  }
}

export default App;
