import { v4 as uuidv4 } from 'uuid';
import { EventEmitter } from "events";
import { session } from "./session";
import { WS_HOSTNAME } from '../config';

class Socket extends EventEmitter {

  websocket: WebSocket | null = null;

  connected: boolean = false;

  promises: Record<string, any> = {};

  constructor() {

    super();

    session.addListener('options', this.onOptions.bind(this))

    // connect now
    this.connect();

  }

  onOptions({ key, value }: any) {

    console.log('options socket', key, value);

    if(key === 'customer') {

      if(this.connected === true) {

        this.disconnect();

      }
      
      this.connect();

    }

  }

  connect() {

    // check authenticated
    if(!session.getOption('customer')) {

      this.disconnect();
      return;

    }

    if(this.connected === true) {

      return;

    }

    this.connected = true;
    
    this.websocket = new WebSocket(`wss://${WS_HOSTNAME || 'localhost:9000'}`);
    this.websocket.onopen = this.onOpen.bind(this);
    this.websocket.onmessage = this.onMessage.bind(this);
    this.websocket.onclose = this.onClose.bind(this);
    this.websocket.onerror = this.onError.bind(this);

  }

  async onOpen() {

    // emit
    this.emit('status', 'Subscribing');

    // socket state
    this.emit('socketState', {
      type: 'socketState',
      date: new Date(),
      data: {
        message: `Subscribing to Observation.create for customer ${session.getOption('customer')}`,
      },
    });

    // subscribe
    this.send('subscribe', {
      "entity": "Observation",
      "customers": [session.getOption('customer')],
      "events": ['create'],
    });

    this.emit('socketState', {
      type: 'socketState',
      date: new Date(),
      data: {
        message: `Subscribing to Alarm.create for customer ${session.getOption('customer')}`,
      },
    });
    
    // subscribe
    await this.send('subscribe', {
      "entity": "Alarm",
      "customers": [session.getOption('customer')],
      "events": ['create'],
    });
    
  }

  onMessage(message: any) {
    
    // parse
    const raw = JSON.parse(message.data);

    if(raw.event) {

      if(raw.event !== 'error') {

        // emit
        this.emit(raw.event, raw?.data);

      }

      // send to internal event 
      this.onEvent(raw.event, raw?.data, raw?.requestId);

    }

    if(raw.entity) {

      this.onEntityEvent(raw);

    }

  }

  onError(error: any) {

    this.emit('socketState', {
      type: 'socketState',
      date: new Date(),
      data: {
        message: 'Socket error',
        error,
      },
    });

  }

  onClose(event: CloseEvent) {

    this.connected = false;

    // emit
    this.emit('status', 'Disconnected');

    // socket state
    this.emit('socketState', {
      type: 'socketState',
      date: new Date(),
      data: {
        message: `Socket closed: ${event.reason} (${event.code})`,
      },
    });

    window.setTimeout(() => {

      // retry reconnect
      this.connect();

    }, 1000);

  }

  onEvent(event: string, data: any, requestId?: string) {

    if(requestId && this.promises[requestId]) {

      this.promises[requestId][event === 'error' ? 'reject' : 'resolve']({
        requestId,
        event,
        data,
      });

      // done
      delete this.promises[requestId];
      
    }

    if(event === 'error') {

      // socket state
      this.emit('socketState', {
        type: 'socketState',
        date: new Date(),
        data: {
          message: data.error,
        },
      });

    } else
    if(event === 'subscribed') {

      // emit
      this.emit('status', 'Connected');

      // socket state
      this.emit('socketState', {
        type: 'socketState',
        date: new Date(),
        data: {
          message: `Subscribed succesfully (Req: ${requestId})`,
        },
      });

    }

  }

  onEntityEvent(data: any) {

    this.emit('entityEvent', data);

  }

  send(event: string, data: any, requestId: string | null = null) {

    // return promise
    return new Promise((resolve, reject) => {

      // add a request id if we did not get one
      requestId = requestId || uuidv4();

      // save to promise resolver list
      this.promises[requestId] = { resolve, reject };

      // send request with token and request id
      this.websocket?.send(JSON.stringify({
        requestId,
        event,
        token: session.getToken(),
        data,
      }));

      setTimeout(() => {

        reject();

      }, 10 * 1000);

    });

  }

  disconnect() {

    this.websocket?.close();

  }
  

}

// singleton
export const socket = new Socket();