import { Component, PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';

type Props = PropsWithChildren & {
  date: Date,
  data: {
    message: string,
    meta?: any
  },
};

export class SocketState extends Component {

  props: Props

  constructor(props: Props) {

    super(props);

    this.props = props;
    
    console.log(props);

  }

  render() {
  
    return <Alert variant={'secondary'} className={'p-0 mb-2 d-flex'}>
      <div className={'timestamp'}>
        <span>{this.props.date?.toLocaleTimeString()}</span>
      </div>
      <div>
        {this.props.data.message}
        {this.props.data.meta ? <div>
          <hr className={'m-1'} /><pre className={'m-1'}>{JSON.stringify(this.props.data.meta)}</pre>
        </div> : null}
      </div>
    </Alert>

  };

}