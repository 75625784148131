import { EventEmitter } from "events";
import { xgacRequest } from "../lib/xgacRequest";
import { socket } from "./socket";

class Session extends EventEmitter {

  token: string | null = null;

  assets: any[] = [];

  options: any = {};

  context: any = null;

  assetRequestInProgress: boolean = false;

  contextRequestInProgress: boolean = false;

  constructor() {

    super();

    // get from storage
    this.setToken(localStorage.getItem('token'));

  }

  async setToken(token: string | null) {

    // check if new
    if(this.token === token) {

      return;
      
    }

    // save
    this.token = token;

    // set to axios
    xgacRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const contextSuccess = await this.getContext();

    // check string
    if(token === null || contextSuccess === false) {

      // stop
      this.setOption('customer', null);

      // delete item from local storage
      localStorage.removeItem('token');

      // emit
      this.emit('token', null);

      return;

    }

    // set in local storage
    localStorage.setItem('token', token);
    
    // emit
    this.emit('token', token);

  }

  setOption(key: string, value: any) {

    console.log('Setting option', key, value)

    localStorage.setItem(key, JSON.stringify({ _v: value }));
    this.options[key] = value;

    this.emit('options', { key, value });

    if(key === 'customer') {

      this.getAssets();

    }

  }

  getOption(key: string) {

    if(!this.options[key]) {

      const value = JSON.parse(localStorage.getItem(key) || '{"_v": null}')._v;

      if(value) {

        this.setOption(key, value);

      }

    }

    return this.options[key];

  }

  async getContext(): Promise<any> {

    if(this.contextRequestInProgress === true) {

      await new Promise(resolve => setTimeout(resolve, 50));
      return this.getContext();

    }

    if(this.context !== null) {

      return this.context;

    }

    // start
    this.contextRequestInProgress = true;

    try { 

      // load
      this.context = (await xgacRequest.get('/context')).data;

    } catch(e) {

      this.logout();
      return false;

    }

    this.context.customerTreeFlat = this.context.customerTreeFlat.sort((a: any, b: any) => {

      return a.name.trim() > b.name.trim();

    })

    // check if dont have acustomer in memory
    if(!this.getOption('customer')) {

      // set default customer
      this.setOption('customer', this.context.customerTreeFlat[0]._id);

    }

    // get assets
    this.setOption('assets', await this.getAssets());

    // stop
    this.contextRequestInProgress = false;

    // return
    return this.context;

  }

  async getAssets(): Promise<any[]> {

    if(this.getOption('customer') === null) {

      // save
      this.setOption('assets', []);

      return [];
      
    }

    if(this.assetRequestInProgress === true) {

      await new Promise(resolve => setTimeout(resolve, 50));
      return this.getAssets();

    }

    // start
    this.assetRequestInProgress = true;

    try{

      this.assets = await this._getAssets();

    } catch(e) {

      // save
      this.setOption('assets', []);

      this.logout();
      return [];

    }

    // stop
    this.assetRequestInProgress = false;

    // save
    this.setOption('assets', this.assets);

    return this.assets;

  }

  async _getAssets(page = 0, assets: any[] = []): Promise<any[]> {

    const assetRequest = await xgacRequest.get('/assets', {
      params: {
        'customer._id': this.getOption('customer'),
        $max: 100,
        $offset: page * 100,
      },
    });

    // combine
    assets.push(...assetRequest.data.result);

    if(assets.length < assetRequest.data.total) {

      return this._getAssets(page + 1, assets);

    }

    return assets;
    
  }

  logout() {

    console.log('Logging out');

    this.context = null;
    this.assets = [];

    this.setOption('customer', null);
    this.setOption('assets', []);
    this.setToken(null);

    socket.disconnect();

  }

  getToken(): string | null {

    return this.token;

  }


}

// singleton
export const session = new Session();