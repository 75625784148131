import { Component, PropsWithChildren } from 'react';
import { Alert, Badge } from 'react-bootstrap';
import { xgacRequest } from '../../lib/xgacRequest';

type StateObject = {
  observation: any,
  zoneName: string,
}

type Props = PropsWithChildren & {
  data: any;
  date: Date;
  color: string;
  showModal: Function;
};

const zoneCache: any = {};
const getZoneName = async (zoneId: string) => { 

  if(zoneCache[zoneId]) {

    return zoneCache[zoneId].name;

  }

  const zone = await xgacRequest.get(`/zones/${zoneId}`);

  zoneCache[zoneId] = zone.data;

  return zone.data.name;

};

export class Observation extends Component {

  state: StateObject;

  props: Props

  data: any;

  pubsubMs: number;

  constructor(props: Props) {

    super(props);

    this.state = {
      observation: props.data,
      zoneName: '',
    }

    this.props = props;
    this.data = props.data;

    const mainDelta = this.data.deltas.main.value;
    let longestStep = 0;
    for(const step in this.data.deltas.steps) {

      const delta = this.data.deltas.steps[step];
      if(delta.value > longestStep) {

        longestStep = delta.value;

      }

    }
    this.pubsubMs = mainDelta - longestStep; 

    if(this.data.meta.zone) {

      getZoneName(this.data.meta.zone._id).then((zoneName) => {

        this.setState({
          zoneName: zoneName,
        });

      });

    }

  }

  render() {
  
    return <Alert variant={'secondary'} className={`p-0 mb-2 d-flex ${this.data.validPosition?.value === false ? 'validPositionFalse' : ''} asset-${this.data.asset._id}`}>
      <div className={'timestamp'} style={{ backgroundColor: this.props.color }}>
        <span>{this.props.date?.toLocaleTimeString()}</span>
      </div>
      <table className={'table mb-0 table-sm'}>
        <tbody>
          <tr>
            <td style={{ width: '230px', maxWidth: '230px', wordBreak: 'break-all', overflow: 'hidden', borderRight: '2px solid #999' }}>
              {this.data.validPosition?.value === false ? <div>
                <Badge bg={'warning'}>{this.data.validPosition.reason}</Badge>
              </div> : null}
              <strong><small>{this.data.event}</small></strong><br />
              <small className={'text-muted'} style={{ fontSize: 10 }}>{Object.keys(this.data.deltas.steps).map(step => {

                const delta = this.data.deltas.steps[step];

                return <div key={step}><span style={{ minWidth: 20, display: 'inline-block' }}>{delta.value}</span> {step}</div>

                })}
                <div><span style={{ minWidth: 20, display: 'inline-block' }}>{this.pubsubMs}</span> pub/sub</div>
                <div>= {this.data.deltas.main.value}ms total</div>
              </small>
            </td>
            <td style={{ width: '350px', borderRight: '2px solid #999', paddingLeft: '10px' }}>
              {this.data.asset.name}<br />
              <small className={'text-muted'}>Asset: {this.data.asset._id}</small><br />
              <small className={'text-muted'}>Device: {this.data.device._id}</small>
            </td>
            <td style={{ paddingLeft: '10px' }}>
              {this.data.asset.position ?
                <div>
                  <strong>{this.data.asset.position.properties?.address?.formattedAddress || <strong className={'text-danger'}>No address</strong>} {this.data.meta.knnResult?.debugInfo.formattedAddress && this.data.meta.knnResult?.debugInfo.formattedAddress !== this.data.asset.position.properties?.address?.formattedAddress ? `(KNN: ${this.data.meta.knnResult?.debugInfo.formattedAddress})` : ''}</strong>&nbsp;
                  <Badge bg="secondary" onClick={() => this.props.showModal(this.data)} className={'cursor-pointer'}>View raw</Badge>&nbsp;
                  <a target="_blank" href={`https://console.cloud.google.com/logs/query;query=%22${this.data._id}%22;timeRange=${this.data.meta.gatewayDateTime}%2F${this.data.meta.gatewayDateTime}--PT1H?project=xg-staging`} rel="noreferrer">
                    <Badge bg="secondary" className={'cursor-pointer'}>Logs</Badge>
                  </a>
                  <br />
                  <small>
                    {this.data.meta.rssi ? <span>RSSI: {this.data.meta.rssi}&nbsp;-&nbsp;</span> : null}
                    {this.data.meta.snr ? <span>SNR: {this.data.meta.snr}&nbsp;-&nbsp;</span> : null}
                    Accuracy: {Math.round(this.data.asset.position.properties.accuracy)}m
                    {this.data.asset.position.properties.speed ? <span>&nbsp;- Speed: {Math.round(this.data.asset.position.properties.speed)}km/h<small>±{Math.round(this.data.asset.position.properties.speedAccuracy)}km/h</small></span> : ''}
                    {this.data.asset.position.properties.heading ? <span>&nbsp;- Heading: {Math.round(this.data.asset.position.properties.heading)}°<small>±{Math.round(this.data.asset.position.properties.headingAccuracy)}°</small></span> : ''}
                    {this.data.asset.position.properties.altitudeAccuracy ? <span>&nbsp;- Altitude: {Math.round(this.data.asset.position.properties.altitude)}m<small>±{Math.round(this.data.asset.position.properties.altitudeAccuracy)}m</small></span> : ''}
                    &nbsp;- Source: {this.data.asset.position.properties?.provider || <strong className={'text-danger'}>Missing position source</strong>} {this.data.meta.knnResult?.distance ? <small>(distance: {Number.parseFloat(this.data.meta.knnResult?.distance).toFixed(5)})</small> : ''}
                  </small>
                </div> :
                <div>(Invalid asset position)</div>}
              {this.data.meta.zone ? <div>
                <div className={'beacon'}>{this.state.zoneName} ({this.data.meta.zone._id})</div>
              </div> : null}
              {this.data.beacons ?
                <div>
                  {this.data.beacons.map((beacon: any) => {

                    if(this.data.asset.position.properties?.provider === 'knn') {

                      return <div key={beacon.code} className={`beacon beacon-valid ${beacon.beacon !== null ? 'beacon-found' : 'beacon-notfound'}`}>
                        <strong>{beacon.code}</strong> ({beacon.rssi})
                      </div>;

                    }

                    return <div key={beacon.code} className={`beacon ${beacon.valid ? 'beacon-valid' : 'beacon-invalid'} ${beacon.beacon !== null ? 'beacon-found' : 'beacon-notfound'} ${beacon.calc > 0 && beacon.valid === false ? 'zone-invalid' : ''}`}>
                      <strong>{beacon.code}</strong> ({beacon.rssi} / {beacon.calc || '?'})
                    </div>;

                  })}
                </div> : null}
            </td>
          </tr>
        </tbody>
      </table>
    </Alert>

  };

}