import { Component, PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';

type StateObject = {
  observation: any,
}

type Props = PropsWithChildren & {
  data: any;
  date: Date;
  color: string;
  showModal: Function;
};

export class Alarm extends Component {

  state: StateObject;

  props: Props

  data: any;

  constructor(props: Props) {

    super(props);

    this.state = {
      observation: props.data
    }

    this.props = props;
    this.data = props.data;

  }

  render() {
  
    return <Alert variant={'secondary'} className={`p-0 mb-2 d-flex`}>
      <div className={'timestamp'} style={{ backgroundColor: this.props.color }}>
        <span>{this.props.date?.toLocaleTimeString()}</span>
      </div>
      <table className={'table mb-0 table-sm'}>
        <tbody>
          <tr>
            <td style={{ width: '230px', maxWidth: '230px', wordBreak: 'break-all', overflow: 'hidden', borderRight: '2px solid #999' }}>
              <strong>NEW ALARM ({this.props.data._id})</strong><br />
              Customer: {this.props.data.customer._id}
            </td>
          </tr>
        </tbody>
      </table>
    </Alert>

  };

}