import React from "react";
import Select, { createFilter } from "react-select";

import MenuList from "./menuList";
import Option from "./option";

import "./reactSelect.scss";

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: '30px',
    padding: '0 6px'
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: (state: any) => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: '30px',
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    maxWidth: '150px',
  }),
};

const ReactSelect = (props: any) => {

  return (
    <Select
      {...props}
      styles={customStyles}
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        MenuList,
        Option
      }}
    />
  );

}

export default ReactSelect;
export * from 'react-select';