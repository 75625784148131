import { Component, createRef, PropsWithChildren, RefObject } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { socket } from '../../classes/socket';
import { Observation } from './observation';
import { SocketState } from './socketState';
import randomColor from 'randomcolor';
import ReactJson from 'react-json-view';
import { Alarm } from './alarm';

type StateObject = {
  messages: any[];
  showModal: boolean;
  modalContent: any;
}

const messageComponents: any = {
  Observation: Observation,
  socketState: SocketState,
  Alarm: Alarm,
}

export class ObservationList extends Component {

  state: StateObject;

  endRef: RefObject<HTMLDivElement>;

  scrollListRef: RefObject<HTMLDivElement>;

  listenersBound: boolean = false;

  showModalFuction: Function;

  constructor(props: PropsWithChildren) {

    super(props);

    this.endRef = createRef();
    this.scrollListRef = createRef();

    this.state = {
      messages: [],
      showModal: false,
      modalContent: {},
    }

    this.showModalFuction = this.showModal.bind(this);

  }

  componentDidMount() {

    if(this.listenersBound === true) {

      return;

    }

    this.listenersBound = true;

    socket.addListener('entityEvent', this.onEntityEvent.bind(this));
    socket.addListener('socketState', this.addMessage.bind(this));


  }

  onEntityEvent({ entity, operation, document}: any) {

    if(entity === 'Observation') {

      this.onObservation(document);

    } else
    if(entity === 'Alarm') {

      this.addMessage({
        type: 'Alarm',
        date: new Date(),
        data: document,
        color: '#d0382a',
      })

    }

  }

  onObservation(observation: any) {

    this.addMessage({
      type: 'Observation',
      date: new Date(observation.meta.deviceDateTime),
      data: observation,
      color: randomColor({
        seed: observation.asset._id,
      }),
    })

  }

  addMessage(message: any) {

    const el = this.scrollListRef?.current;
    const currentScroll = (el?.scrollTop || 0) + (el?.clientHeight || 0);
    const maxScroll = el?.scrollHeight || 0;

    console.log('scroll', { maxScroll, currentScroll, }, currentScroll / maxScroll);

    this.state.messages.push(message);
    this.forceUpdate();

    // wait one tick
    window.setTimeout(() => {

      if(currentScroll / maxScroll > 0.97) {

        // scroll down
        this.endRef?.current?.scrollIntoView();

      }

    }, 0);

  }

  showModal(observation: any) {

    this.setState({
      showModal: true,
      modalContent: observation,
    });

  }

  closeModal() {

    this.setState({
      showModal: false,
      modalContent: {},
    });

  }

  render() {
  
    return [
      <div key={0} ref={this.scrollListRef} style={{ height: '100%', overflow: 'scroll', padding: 15, fontSize: 14 }}>
        {this.state.messages.map((message: any, index: number) => {

          const MessageComponent = messageComponents[message.type];

          if(MessageComponent) {

            return <MessageComponent key={index} date={message.date} data={message.data} color={message.color} showModal={this.showModalFuction} />

          }

          return null;

        })}
        {this.state.messages.length === 0 ?
          <Alert variant={'secondary'}>
            There are no observations yet.
          </Alert> : null}
          <div ref={this.endRef}></div>
      </div>,
      <div key={1}>
        <Modal show={this.state.showModal} onHide={this.closeModal.bind(this)} animation={true}>
          <Modal.Header closeButton>
            <Modal.Title>Raw Observation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactJson name={null} src={this.state.modalContent} displayDataTypes={false}  />
          </Modal.Body>
        </Modal>
      </div>
    ];

  };

}