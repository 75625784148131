import { Component, PropsWithChildren } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import ReactSelect, { MultiValue } from '../../lib/react-select/reactSelect';
import logo from '../../assets/logo.png';
import { session } from '../../classes/session';
import { socket } from '../../classes/socket';
import { AUTH_CLIENT_ID, AUTH_HOSTNAME } from '../../config';

type StateObject = {
  token: string | null,
  status: string,
  assets: any[],
  followAsset: string,
  filterAsset: string[],
  customers: any[],
  customer: string,
}

export class Menu extends Component {

  state: StateObject

  token: string | null = null;

  constructor(props: PropsWithChildren) {

    super(props);

    this.state = {
      token: null,
      status: 'Disconnected',
      assets: (session.getOption('assets') || []).map((asset: any) => ({
        value: asset._id,
        label: asset.name,
      })),
      filterAsset: session.getOption('filterAsset') || [],
      followAsset: session.getOption('followAsset'),
      customers: [],
      customer: session.getOption('customer'),
    };

  }

  componentDidMount() {

    socket.addListener('status', this.setStatus.bind(this));
    session.addListener('token', this.setToken.bind(this));
    session.addListener('options', this.setOption.bind(this));

    const token = session.getToken();
    if(token !== null) {

      this.setToken(token);

    }


  }

  componentWillUnmount() {

    session.removeListener('token', this.setStatus.bind(this));
    socket.removeListener('status', this.setToken.bind(this));
    session.removeListener('options', this.setOption.bind(this));

  }

  setOption({ key, value }: { key: string; value: any }) {

    console.log('debug debug debug', key, value);

    if(key === 'customer') {

      this.setState({
        customer: value
      });

    } else
    if(key === 'assets') {

      const assetsWithPosition = value.filter((asset: any) => asset.position !== null);

      if(assetsWithPosition.length > 0 && !session.getOption('followAsset')) {

        session.setOption('followAsset', assetsWithPosition[0]._id);

      }

      this.setState({
        assets: assetsWithPosition.map((asset: any) => ({
          value: asset._id,
          label: asset.name,
        })),
        followAsset: session.getOption('followAsset'),
        filterAsset: session.getOption('filterAsset') || [],
      });

    }

  }

  setStatus(status: string) {

    console.log('Socket status:', status);

    this.setState({
      status,
    })

  }

  async setToken(token: string) {

    if(this.token === token) {

      return;
      
    }

    this.token = token;
    this.setState({
      token,
    });

    setTimeout(async () => {

      const context = await session.getContext();

      this.setState({
        customers: context.customerTreeFlat.map((customer: any) => ({
          value: customer._id,
          label: customer.name,
        })),
        customer: session.getOption('customer'),
      });

    }, 0);

    setTimeout(async () => {

      // const assets = (await session.getAssets()).filter(asset => asset.position !== null);

      // if(assets.length > 0) {

      //   session.setOption('followAsset', assets[0]._id);

      // }

      // this.setState({
      //   assets: assets.map((asset: any) => ({
      //     value: asset._id,
      //     label: asset.name,
      //   })),
      //   followAsset: session.getOption('followAsset'),
      //   filterAsset: session.getOption('filterAsset') || [],
      // });

    }, 0);

  }

  selectChange(item: string, option: any) {

    session.setOption(item, option.value);

    this.setState({
      [item]: option.value,
    });

  }

  selectChangeCustomer(option: any) {

    session.setOption('followAsset', null);
    session.setOption('filterAsset', []);
    session.setOption('customer', option.value);

    this.setState({
      customer: option.value,
    });

  }

  selectChangeMulti(item: string, newValue: MultiValue<any>) {

    const values = newValue.map((option) => option.value);

    session.setOption(item, values);

    this.setState({
      [item]: values,
    });

  }

  render() {
  
    return [
    <Row key={'row'} className={'p-1 menuRow'} style={{ fontSize: 14, borderBottom: '2px solid black' }}>
      <Col md={3} className={'d-md-flex align-items-stretch d-none'}>
        <img alt="logo" src={logo} style={{ maxHeight: 40, marginLeft: 10, marginTop: 7, marginBottom: 7, marginRight: 20 }} />
        <div style={{ flexGrow: 1 }} className={'d-none d-xl-block'}>
          <strong>Status:</strong><br />
          <Alert style={{ padding: 3, paddingLeft: 10, marginBottom: 0 }} variant="light">{this.state.status}</Alert>
        </div>
      </Col>
      <Col md={2}>
        {this.state.token !== null ?
          <div>
            <strong>Customer:</strong>
            <ReactSelect
              onChange={this.selectChangeCustomer.bind(this)}
              value={this.state.customers.filter((customer) => customer.value === this.state.customer)}
              options={this.state.customers} />
          </div>: null}
      </Col>
      <Col md={2}>
        {this.state.token !== null ?
          <div>
            <strong>Follow Asset:</strong>
            <ReactSelect
              onChange={this.selectChange.bind(this, 'followAsset')}
              value={this.state.assets.filter((asset) => asset.value === this.state.followAsset)}
              options={this.state.assets} />
          </div>: null}
      </Col>
      <Col md={3}>
        {this.state.token !== null ?
          <div>
            <strong>Only show assets:</strong>
            <ReactSelect
              isMulti={true}
              placeholder={'Show all assets'}
              onChange={this.selectChangeMulti.bind(this, 'filterAsset')}
              value={this.state.assets.filter((asset) => (this.state.filterAsset || []).indexOf(asset.value) !== -1)}
              options={this.state.assets} />
          </div>: null}
      </Col>
      <Col md={2}>
        {this.state.token !== null ?
          <div>
            <strong>Tools:</strong><br />
            <Button size={'sm'} style={{ marginBottom: 0 }} variant="light" onClick={() => window.alert('Not implemented yet')}>v1.3.1</Button>&nbsp;
            <Button size={'sm'} style={{ marginBottom: 0 }} variant="danger" onClick={() => session.logout()}>Logout</Button>
          </div>
          :
          <div>
            <strong>Tools:</strong><br />
            <a href={`https://${AUTH_HOSTNAME}/auth/login?clientId=${AUTH_CLIENT_ID}&redirectUri=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/auth/callback`)}`}><Button size={'sm'} variant="dark">Authenticate</Button></a>
          </div>}
      </Col>
    </Row>,
    <style key={'style'}>
    {(this.state.filterAsset || []).length > 0 && this.state.assets.map(asset => {

      if(this.state.filterAsset.indexOf(asset.value) > -1) {

        return null;

      }

      return `.asset-${asset.value} {

        display: none !important;

      }`;

    })}
    </style>
    ];

  };

}