const useNodeEnv = (() => {
  try {
    return typeof process.env !== 'undefined'
  } catch (e) {
    return false;
  }
})();

const env = <T extends string | boolean = string>(key: string, defaultValue?: T): T => {

  // @ts-ignore
  const value = (useNodeEnv ? process.env : import.meta.env)[key]  ?? defaultValue;

  if (typeof value === 'undefined') {
    throw new Error(`env key: ${key} is undefined. Make sure it's defined`);
  }
  
  return value as unknown as T;
}

export const AUTH_CLIENT_ID = env('REACT_APP_AUTH_CLIENT_ID');
export const AUTH_HOSTNAME = env('REACT_APP_AUTH_HOSTNAME');
export const WS_HOSTNAME = env('REACT_APP_WS_HOSTNAME');
export const MAIN_API_HOSTNAME = env('REACT_APP_MAIN_API_HOSTNAME');